.sidebar_phone{
    height: calc(100vh - 61px);
}

.message_phone{
    height: 100vh;
}

.cursor{
    cursor: pointer;
}